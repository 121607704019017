import { createClient } from 'contentful';
import { gql, GraphQLClient } from 'graphql-request';
import { getEnv } from '../../config';
export const queries = {
    getContentfulExercisesByIds: (contentfulIds, fetchSpanish) => gql `
    query {
      carePlanExerciseCollection(
        ${fetchSpanish ? 'locale: "es-US",' : ''}
        where: { sys: { id_in: ${JSON.stringify(contentfulIds)} } }
      ) {
        items {
          exerciseTitle
          exerciseDescription {
            json
          }
          defaultPortraitIntroAsset {
            introVideoName
            introVideoAsset {
              url
            }
            lengthInSeconds
          }
          defaultPortraitLoopAsset {
            loopVideoName
            loopVideoAsset {
              url
            }
            durationInSeconds
          }
          defaultLandscapeIntroAsset {
            introVideoName
            introVideoAsset {
              url
            }
            lengthInSeconds
          }
          defaultLandscapeLoopAsset {
            loopVideoName
            loopVideoAsset {
              url
            }
            durationInSeconds
          }

          rightPortraitIntroAsset {
            introVideoName
            introVideoAsset {
              url
            }
            lengthInSeconds
          }
          rightPortraitLoopAsset {
            loopVideoName
            loopVideoAsset {
              url
            }
            durationInSeconds
          }
          rightLandscapeIntroAsset {
            introVideoName
            introVideoAsset {
              url
            }
            lengthInSeconds
          }
          rightLandscapeLoopAsset {
            loopVideoName
            loopVideoAsset {
              url
            }
            durationInSeconds
          }

          isTwoSided
          exerciseThumbnail {
            url
          }
          startingPosition {
            positionName
            id
          }
          internalId
          flip
          carePathTagsCollection(limit: 10) {
            items {
              tag
            }
          }
          sys {
            id
          }
        }
      }
    }
  `,
    getSignupStripeProducts: () => gql `
    query {
      availableSignUpPlansCollection {
        items {
          stripePlansCollection {
            items {
              planDisplayName
              stripeId
              zeroPriceLabel
            }
          }
        }
      }
    }
  `
};
const contentfulConfig = getEnv().contentfulConfig;
const ContentfulPreviewConfigTemp = Object.assign({}, contentfulConfig);
export const contentfulRestClient = createClient(contentfulConfig);
ContentfulPreviewConfigTemp.accessToken = 'i6bCTLzxeL5o_ab8B4vFG8cgejxWsK9GZsFDOzJkdEk';
const ContentfulPreviewConfig = Object.assign({ host: 'preview.contentful.com' }, ContentfulPreviewConfigTemp);
export const contentfulRestPreviewClient = createClient(ContentfulPreviewConfig);
export const contentfulGraphQLClient = new GraphQLClient(`https://graphql.contentful.com/content/v1/spaces/${contentfulConfig.space}/environments/${contentfulConfig.environment}`, {
    headers: {
        authorization: `Bearer ${contentfulConfig.accessToken}`
    }
});
