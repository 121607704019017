import { __awaiter } from "tslib";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import dataEn from '../../../contentful/en-US/en-US-screen.json';
import dataEs from '../../../contentful/es-US/es-US-screen.json';
import { LOG } from '../../log';
import { i18n } from '../i18next';
import { contentfulGraphQLClient, contentfulRestClient, contentfulRestPreviewClient, queries } from './contenfulClient';
import { ContentfulContentType, ScreenComponentData, ScreenData } from './types';
const getLocaleFromLang = (lang) => (lang === 'es' ? 'es-US' : 'en-US');
function convertToMarkdown(document) {
    return documentToHtmlString(document, {
        renderMark: {
            [MARKS.BOLD]: text => `*${text}*`,
            [MARKS.ITALIC]: text => `__${text}__`
        },
        renderNode: {
            [BLOCKS.HEADING_1]: (node, next) => `# ${next(node.content)}`,
            [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}\n`,
            [BLOCKS.OL_LIST]: (node, next) => `${next(node.content)}\n`,
            [BLOCKS.UL_LIST]: (node, next) => `${next(node.content)}\n`,
            [BLOCKS.LIST_ITEM]: (node, next) => `* ${next(node.content)}`
        }
    }).trim();
}
const TAG_OPERATOR = 'metadata.tags.sys.id[in]';
function queryByTag(content_type, tag) {
    return __awaiter(this, void 0, void 0, function* () {
        return contentfulRestClient.getEntries({
            [TAG_OPERATOR]: tag,
            content_type
        });
    });
}
export default class ContentfulActions {
    constructor(sdk) {
        Object.defineProperty(this, "sdk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sdk
        });
    }
    get store() {
        return this.sdk.contentful.S;
    }
    loadAppointmentDetailsContent() {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield contentfulRestClient.getEntries({
                content_type: ContentfulContentType.AppointmentTypeDetails,
                locale: getLocaleFromLang(i18n.language)
            });
            const result = entries.items.map(val => {
                const { title, description, typeAlias, appointmentTypeIds } = val.fields;
                return { title, description, typeAlias, appointmentTypeIds };
            });
            this.store.setAppointmentDetails(result);
            return result;
        });
    }
    loadClinicianBioContent() {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield contentfulRestClient.getEntries({
                content_type: ContentfulContentType.ClinicianBio,
                locale: getLocaleFromLang(i18n.language)
            });
            const result = entries.items.map(val => {
                const { clinicianName, headshot, jobTitle, certificationsTitle, headlineText, about, specialties, previousAffiliations, timePracticing, scopeOfExperience, educationAndAwards, professionalMembership, languages, pronouns, npiNumber, overallRating, providerCognitoUuid, healthieId } = val.fields;
                return {
                    clinicianName,
                    headshotUri: headshot === null || headshot === void 0 ? void 0 : headshot.fields.file.url,
                    jobTitle,
                    certificationsTitle,
                    headlineText: documentToHtmlString(headlineText),
                    headlineTextMarkdown: convertToMarkdown(headlineText),
                    about: documentToHtmlString(about),
                    aboutMarkdown: convertToMarkdown(about),
                    specialties: documentToHtmlString(specialties),
                    specialtiesMarkdown: convertToMarkdown(specialties),
                    previousAffiliations: documentToHtmlString(previousAffiliations),
                    previousAffiliationsMarkdown: convertToMarkdown(previousAffiliations),
                    timePracticing: documentToHtmlString(timePracticing),
                    timePracticingMarkdown: convertToMarkdown(timePracticing),
                    scopeOfExperience: documentToHtmlString(scopeOfExperience),
                    scopeOfExperienceMarkdown: convertToMarkdown(scopeOfExperience),
                    educationAndAwards: documentToHtmlString(educationAndAwards),
                    educationAndAwardsMarkdown: convertToMarkdown(educationAndAwards),
                    professionalMembership: documentToHtmlString(professionalMembership),
                    professionalMembershipMarkdown: convertToMarkdown(professionalMembership),
                    languages: documentToHtmlString(languages),
                    languagesMarkdown: convertToMarkdown(languages),
                    pronouns,
                    npiNumber,
                    overallRating,
                    uuid: providerCognitoUuid,
                    healthieId: healthieId
                };
            });
            this.store.setClinicianBios(result);
            return result;
        });
    }
    fetchContentType(contentType, locale) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield contentfulRestClient
                .getEntries({
                content_type: contentType,
                locale: locale || getLocaleFromLang(i18n.language)
            })
                .then((entries) => {
                return entries.items;
            });
        });
    }
    fetchByType(contentType) {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield contentfulRestClient.getEntries({
                content_type: ContentfulContentType.CTACard,
                locale: getLocaleFromLang(i18n.language)
            });
            return entries;
        });
    }
    fetchPreviewByID(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield contentfulRestPreviewClient.getEntry(id).then((entry) => {
                this.store.setEntry(entry);
                return entry;
            });
        });
    }
    load(refetch = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                (!this.store.isLoaded || refetch) &&
                    (yield Promise.all([
                        this.loadAppointmentDetailsContent(),
                        this.loadClinicianBioContent(),
                        this.loadAppointmentTypes(),
                        this.loadProviderTypes(),
                        this.loadSelectInputs(),
                        this.loadAlexisContentCards(),
                        this.loadScreenData(),
                        this.loadVisitTestimonialCards(),
                        this.loadAlexisVideoContent()
                    ]));
                (!this.store.isLoaded || refetch) && this.store.setIsLoaded(true);
            }
            catch (e) {
                LOG.error('ContentfulActions: load', e);
            }
        });
    }
    loadScreenData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setScreenDataLoading(true);
                const loc = getLocaleFromLang(i18n.language);
                yield Promise.all([this.loadScreenDataByLocale(loc), this.loadScreenDataByLocale(loc)]);
            }
            catch (e) {
                LOG.error('ContentfulActions: loadScreenData', e);
                try {
                    yield this.loadScreenDataByLocaleFromFile('en-US');
                    yield this.loadScreenDataByLocaleFromFile('es-US');
                }
                catch (error) {
                    LOG.error('ContentfulActions: loadScreenData', error);
                }
            }
            finally {
                this.store.setScreenDataLoading(false);
            }
        });
    }
    getScreenDataByLocale(entries, locale) {
        try {
            const results = entries.map((e) => {
                const screenComponentsRes = [];
                e.fields.screenComponent.forEach(c => {
                    var _a;
                    const sc = new ScreenComponentData(c.fields.screenComponentId, c.fields.type, (_a = c.fields.textComponent) !== null && _a !== void 0 ? _a : '', c.fields.richTextComponent ? c.fields.richTextComponent : null, c.fields.imageComponent ? 'https:' + c.fields.imageComponent.fields.file.url : '');
                    screenComponentsRes.push(sc);
                });
                return new ScreenData(e.fields.screenId, screenComponentsRes);
            });
            return results;
        }
        catch (ex) {
            LOG.error('ContentfulActions: getScreenDataByLocale', ex);
        }
    }
    loadScreenDataByLocale(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const entries = yield this.fetchContentType(ContentfulContentType.ScreenData);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = this.getScreenDataByLocale(entries, locale);
                results && this.store.setScreenData(locale, results);
            }
            catch (ex) {
                LOG.error('ContentfulActions: loadScreenDataByLocale', ex);
                yield this.loadScreenDataByLocaleFromFile('en-US');
            }
        });
    }
    loadScreenDataByLocaleFromFile(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = locale === 'en-US' ? dataEn : dataEs;
            const entries = data.items.map(e => {
                const screenComponent = e.fields.screenComponent.map(a => {
                    const entry = data.includes.Entry.find(k => {
                        return k.sys.id === a.sys.id;
                    });
                    const asset = (entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) &&
                        data.includes.Asset.find(k => {
                            var _a;
                            return k.sys.id === ((_a = entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) === null || _a === void 0 ? void 0 : _a.sys.id);
                        });
                    return Object.assign(Object.assign({}, ((entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) && {
                        fields: Object.assign(Object.assign({}, entry === null || entry === void 0 ? void 0 : entry.fields), { imageComponent: Object.assign(Object.assign({}, entry.fields.imageComponent), { fields: { file: asset === null || asset === void 0 ? void 0 : asset.fields.file } }) }),
                        metadata: entry === null || entry === void 0 ? void 0 : entry.metadata,
                        sys: entry === null || entry === void 0 ? void 0 : entry.sys
                    })), (!(entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) && {
                        fields: entry === null || entry === void 0 ? void 0 : entry.fields,
                        metadata: entry === null || entry === void 0 ? void 0 : entry.metadata,
                        sys: entry === null || entry === void 0 ? void 0 : entry.sys
                    }));
                });
                return {
                    fields: {
                        screenComponent,
                        screenId: e.fields.screenId
                    },
                    metadata: e.metadata,
                    sys: e.sys
                };
            });
            try {
                const results = this.getScreenDataByLocale(entries, locale);
                results && this.store.setScreenData(locale, results);
            }
            catch (ex) {
                LOG.error('ContentfulActions: loadScreenDataByLocaleFromFile', ex);
            }
        });
    }
    getStripeSignupPlans() {
        return __awaiter(this, void 0, void 0, function* () {
            const query = queries.getSignupStripeProducts();
            const data = yield contentfulGraphQLClient.request(query);
            try {
                if (data.availableSignUpPlansCollection.items.length == 0) {
                    return [];
                }
                const products = data.availableSignUpPlansCollection.items[0].stripePlansCollection.items.map((x) => ({
                    productId: x.stripeId,
                    zeroPriceLabel: x.zeroPriceLabel
                }));
                return products;
            }
            catch (e) {
                LOG.error('Could not get signup stripe products', e);
                return [];
            }
        });
    }
    getExercises(contentfulIds, useSpanishFlag) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = queries.getContentfulExercisesByIds(contentfulIds, useSpanishFlag);
            const data = yield contentfulGraphQLClient.request(query);
            const items = data.carePlanExerciseCollection.items;
            return items;
        });
    }
    loadAppointmentTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.AppointmentType);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setAppointmentTypes(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadAppointmentTypes', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadProviderTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield contentfulRestClient.getEntries({
                    content_type: ContentfulContentType.ProviderType,
                    locale: getLocaleFromLang(i18n.language)
                });
                if (entries.errors) {
                    throw new Error(`${entries.errors}`);
                }
                const results = entries.items.map(e => {
                    return Object.assign({}, e.fields);
                });
                this.store.setProviderTypes(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadProviderTypes', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadSelectInputs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.SelectInput);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setSelectInputs(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadSelectInputs', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadAlexisVideoContent() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.alexisVideoContent);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setAlexisVideoContent(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadAlexisVideoContent', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadAlexisContentCards() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.AlexisContentCards);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign(Object.assign({}, e.fields), e.sys);
                });
                this.store.setAlexisContentCards(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadAlexisContentCards', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
                this.store.setIsAlexisContentLoaded(true);
            }
        });
    }
    loadVisitTestimonialCards() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.visitTestimonialCards);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setVisitTestimonialCards(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: loadVisitTestimonialCards', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
                this.store.setIsVisitTestimonialCardsLoaded(true);
            }
        });
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
}
